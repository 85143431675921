const styles = ({ app, typography }) => ({
  container: {
    display: 'flex',
    margin: `${typography.pxToRem(8)} auto 0`,
    maxWidth: app.maxWidth,
    width: '100%',
  },
  box: {
    maxWidth: app.maxWidth,
    width: '100%',
  },
  contactForm: {
    margin: '20px',
  },
  formTextField: {
    marginBottom: '20px',
  }
});

export default styles;
