import React from 'react';
import { withStyles } from '@material-ui/styles';

import styles from './style';

interface Props {
  classes: any;
};

const Footer = ({ classes }: Props) => (
  <footer className={classes.container}>
    © {new Date().getFullYear()} LibraSolutions Inc.
  </footer>
);

export default withStyles(styles)(Footer);
