import React from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/styles';

import styles from './style';

interface Props {
  classes: any;
};

const CorporateInfo = ({ classes }: Props) => (
  <div className={classes.container}>
      <Box boxShadow={3} className={classes.box}>
          <Table className={classes.corporateTable}>
              <TableBody>
                  <TableRow>
                      <TableCell>社名</TableCell>
                      <TableCell>LibraSolutions株式会社</TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell>設立</TableCell>
                      <TableCell>2021年4月</TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell>所在地</TableCell>
                      <TableCell>
                          <p>〒 103-0026</p>
                          <p>東京都中央区日本橋兜町17番2号 兜町第6葉山ビル4階</p>
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell>代表取締役</TableCell>
                      <TableCell>
                          <p>髙橋 基</p>
                          <p>冬木 賢司</p>
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell>事業内容</TableCell>
                      <TableCell>
                          <p>システムエンジニアリングサービス</p>
                          <p>IT・人材コンサルティングサービス</p>
                          <p>ソフトウェアの開発・製作・販売</p>
                          <p>スキルシェアアプリの開発・運営</p>
                      </TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell>資本金</TableCell>
                      <TableCell>1,000,000円</TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell>
                          主要取引銀行<br />
                          （敬称略）
                      </TableCell>
                      <TableCell>三井住友銀行</TableCell>
                  </TableRow>
                  <TableRow>
                      <TableCell>
                          主要取引先<br />
                          （敬称略）
                      </TableCell>
                      <TableCell>
                          <p>株式会社Crane&I</p>
                          <p>株式会社ノースサンド</p>
                          <p>株式会社プログデンス</p>
                      </TableCell>
                  </TableRow>
              </TableBody>
          </Table>
      </Box>
  </div>
);

export default withStyles(styles)(CorporateInfo);
