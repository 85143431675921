import React from 'react';
import Box from '@material-ui/core/Box';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/styles';

import styles from './style';

interface Props {
  classes: any;
};

const Legal = ({ classes }: Props) => (
  <div className={classes.container}>
    <Box boxShadow={3} className={classes.box}>
      <div className={classes.legal}>
        <h2>特定商取引法に基づく表記</h2>
        <hr />
        <Table className={classes.legalTable}>
        <TableBody>
            <TableRow>
                <TableCell>運営会社</TableCell>
                <TableCell>LibraSolutions株式会社</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>運営責任者</TableCell>
                <TableCell>冬木賢司</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>所在地・連絡先</TableCell>
                <TableCell>
                    <p>東京都中央区日本橋兜町17番2号 兜町第6葉山ビル4階</p>
                    <p>info@librasolutions.co.jp</p>
                    <p>お問い合わせフォーム <a href="/contact" target='_blank'>https://www.librasolutions.co.jp/contact</a></p>
                    <p>※お問い合わせについては個人情報保護などのため<br />　サイト内のお問い合わせフォームよりご連絡をお願いいたします。</p>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>営業時間</TableCell>
                <TableCell>
                    <p>平日 10:00～17:00（土・日・祝休）</p>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>販売価格</TableCell>
                <TableCell>
                    <p>
                    商品の販売価格は各商品のページに記載しています。(全て消費税込の価格を表示しております)<br />
                    また、別途当社が定める手数料が販売者（イベント主催者）に発生いたします。<br />
                    商品の取引完了時、お支払額に販売時の手数料15%をかけた額を差し引いて、売上金として計上いたします。
                    </p>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell>商品価格以外に必要な料金</TableCell>
                <TableCell>インターネット接続に必要な通信回線などの費用</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>支払方法</TableCell>
                <TableCell>クレジットカード決済<br />（Visa・MasterCard・American Express・JCB・Diners Club・Discover）</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>支払時期</TableCell>
                <TableCell>商品購入時に決済させていただきます。ご請求日は各カード会社により異なります。</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>サービス提供時期</TableCell>
                <TableCell>決済完了し、イベント開催時刻以降となります。</TableCell>
            </TableRow>
            <TableRow>
                <TableCell>返品・変更について</TableCell>
                <TableCell>
                    <p>
                    購入手続完了後の返品又はキャンセルはできません。<br />
                    ただし、販売者の都合によって提供されなかった場合、速やかに事実関係を調査した上で返金いたします。<br />
                    商品購入後の変更は、同一イベントで販売中の商品で且つ主催者が変更を許諾した場合に限り可能となります。
                    </p>
                </TableCell>
            </TableRow>
            </TableBody>
        </Table>
      </div>
    </Box>
  </div>
);

export default withStyles(styles)(Legal);
