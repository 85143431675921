import { dropRight } from "lodash";

const styles = ({ app, typography }) => ({
  '@global': {
    h2: {
      fontSize: '28pt',
      marginTop: '0.4em',
      marginBottom: '0.8em',
    },
    ol: {
      counterReset: 'item',
      listStyle: 'none',
    },
    "ol > li": {
      display: 'block',
      position: 'relative',
      paddingLeft: '32px',
      "&:before": {
        counterIncrement: 'item',
        content: `counters( item, "-" ) ". "`,
        left: 0,
        position: 'absolute',
      },
    },
  },
  container: {
    display: 'flex',
    margin: `${typography.pxToRem(8)} auto 0`,
    maxWidth: app.maxWidth,
    width: '100%',
  },
  box: {
    maxWidth: app.maxWidth,
    width: '100%',
  },
  privacyPolicy: {
    padding: '3em',
  },
  revision: {
    marginTop: '4em',
    textAlign: 'right',
  }
});

export default styles;
