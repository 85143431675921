import React from 'react';
import Box from '@material-ui/core/Box';
import { withStyles } from '@material-ui/styles';

import styles from './style';

interface Props {
  classes: any;
}

const PrivacyPolicy = ({ classes }: Props) => (
  <div className={classes.container}>
    <Box boxShadow={3} className={classes.box}>
      <div className={classes.privacyPolicy}>
        <h2>プライバシーポリシー</h2>
        <hr />
        <p>
          LibraSolutions株式会社（以下、「当社」といいます。）は、当社のサービスの利用者、当社の取引先、当社の従業員等の皆さまの個人情報保護の重要性について認識し、個人情報の保護に関する法律を遵守すると共に、以下のプライバシーポリシーを定めます。
        </p>
        <h3>第1条（個人情報の定義）</h3>
        <p>
          「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指し、下記を含むものとします。
        </p>
        <ol>
          <li>
            お客さまの氏名、生年月日、性別、その他の特定の個人を識別することができる情報
          </li>
          <li>銀行口座番号、クレジットカード番号</li>
          <li>
            特定の個人を識別できる情報と結びついて使用される住所、電話番号、ログインID、ニックネーム、電子メールアドレス、パスワード等の会員情報
          </li>
          <li>お客さまの本人確認に関する情報</li>
        </ol>
        <h3>第2条（事業者情報）</h3>
        <ul>
          <li>法人名: LibraSolutions株式会社</li>
          <li>住所: 東京都中央区日本橋兜町17番2号 兜町第6葉山ビル4階</li>
          <li>代表者: 代表取締役 冬木 賢司</li>
        </ul>
        <h3>第3条（個人情報の取得方法）</h3>
        <p>当社がお客さまの個人情報を取得する方法は、以下のとおりです。</p>
        <ol>
          <li>端末操作を通じて、直接ご入力いただく方法</li>
          <li>電子メール、郵便、書面、電話等にてご提供いただく方法</li>
          <li>当社の各サービスの利用・閲覧の際に収集する方法</li>
        </ol>
        <h3>第4条（個人情報の利用目的）</h3>
        <p>当社が個人情報を利用する目的は、以下のとおりです。</p>
        <ol>
          <li>
            サービスの利用者に関する個人情報
            <ul>
              <li>当社サービスの提供・運営のため</li>
              <li>
                当社サービスの新機能、更新情報、キャンペーン等及び当社が提供する他サービスのご案内のメールを送付するため
              </li>
              <li>メンテナンス、重要なお知らせ等必要に応じたご連絡のため</li>
              <li>お客さまからのお問い合わせに対応するため</li>
              <li>当社サービスの運営上のトラブル解決のため</li>
              <li>
                利用規約に違反したユーザーや、不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため
              </li>
              <li>
                お客さまにご自身の登録情報の閲覧や変更、削除、ご利用状況の閲覧を行っていただくため
              </li>
              <li>当社サービスのご利用状況を調査、分析するため</li>
              <li>お客さまに適した各サービスをご案内するため</li>
              <li>当社サービスの改善、新たなサービスの開発のため</li>
              <li>アンケート等の調査のご協力、またその結果等をご報告するため</li>
              <li>上記のほか、各サービスの利用規約などで掲示した目的のため</li>
            </ul>
          </li>
          <li>
            取引先に関する個人情報
            <ul>
              <li>当社の営業活動、商談、その他の業務上必要なご連絡のため</li>
              <li>契約の履行、請求支払業務、その他の当社の業務遂行のため</li>
              <li>その他の当社の事業活動に必要な利用のため</li>
            </ul>
          </li>
          <li>
            従業員に関する個人情報
            <ul>
              <li>従業員の人事労務管理のため</li>
              <li>従業員の業務管理のため</li>
              <li>その他の当社の事業活動に必要な利用のため</li>
            </ul>
          </li>
          <li>
            当社の採用活動への応募者に関する個人情報
            <ul>
              <li>応募者へのご連絡のため</li>
              <li>応募者の選考過程での記録作成など管理のため</li>
              <li>その他の採用・募集活動に必要な利用のため</li>
            </ul>
          </li>
        </ol>
        <h3>第5条（利用目的の変更）</h3>
        <p>
          個人情報の利用目的は、利用目的が変更前と関連性を有すると合理的に認められる場合に限って、個人情報の利用目的を変更するものとします。利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、お客さまに通知し、または本ウェブサイト上に公表するものとします。
        </p>
        <h3>第6条（個人データの安全対策について）</h3>
        <p>
          当社は、個人情報を保護するため、情報セキュリティに関する規程に基づき、当該個人情報の管理、個人情報の持ち出し方法の指定、第三者からの不正アクセスの防止等の対策を行い、個人情報の漏洩、紛失、改ざん、破壊等の予防を図ります。
        </p>
        <h3>第7条（個人データの第三者提供）</h3>
        <ol>
          <li>
            当社は、次に掲げる場合を除いて、あらかじめお客さまの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
            <ol>
              <li>
                人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
              </li>
              <li>
                公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
              </li>
              <li>
                国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
              </li>
              <li>
                あらかじめ次の事項を告知あるいは公表し、かつ当社が個人情報保護委員会に届出をしたとき
                <ul>
                  <li>利用目的に第三者への提供を含むこと</li>
                  <li>第三者に提供されるデータの項目</li>
                  <li>第三者への提供の手段または方法</li>
                  <li>
                    本人の求めに応じて個人情報の第三者への提供を停止すること
                  </li>
                  <li>本人の求めを受け付ける方法</li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
            <ol>
              <li>
                当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
              </li>
              <li>
                合併その他の事由による事業の承継に伴って個人情報が提供される場合
              </li>
              <li>
                個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的及び当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合
              </li>
            </ol>
          </li>
        </ol>
        <h3>第8条（個人データの手続）</h3>
        <ol>
          <li>当社は、本人から個人情報の開示を求められたときは、本人に対し、遅滞なくこれを開示します。ただし、開示することにより次のいずれかに該当する場合は、その全部または一部を開示しないこともあり、開示しない決定をした場合には、その旨を遅滞なく通知します。なお、個人情報の開示に際しては、1件あたり1,000円の手数料を申し受けます。
            <ol>
            <li>本人または第三者の生命，身体，財産その他の権利利益を害するおそれがある場合</li>
            <li>当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>
            <li>その他法令に違反することとなる場合</li>
            </ol>
          </li>
          <li>前項の定めにかかわらず，履歴情報および特性情報などの個人情報以外の情報については，原則として開示いたしません。</li>
        </ol>
        <h3>第9条（個人情報の利用停止等）</h3>
        <p>
          当社は、お客さまから個人情報が利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。<br />
          調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。<br />
          当社は、利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なく、これをお客さまに通知します。<br />
          利用停止等に多額の費用を有する場合その他利用停止等を行うことが困難な場合であって、お客さまの権利利益を保護するために必要なこれに代わるべき措置をとれる場合は、この代替策を講じるものとします。
        </p>
        <h3>
          第10条（プライバシーポリシーの改善及び最新のプライバシーポリシーの確認について）
        </h3>
        <p>
          当社は本プライバシーポリシーについて、適宜その改善に努めていきます。最新の本プライバシーポリシーは、当社のウェブサイトでご覧になれます。
          お客さまは、本ウェブサイト等にアクセスするか当社のサービスをご利用になる前に、必ず最新のプライバシーポリシーをご確認ください。お客さまが本ウェブサイト等にアクセスされるか当社のサービスをご利用になられた場合は、最新のプライバシーポリシーの内容に同意されたものとみなします。
        </p>
        <h3>第11条（お問い合わせ先）</h3>
        <p>
        当社のプライバシーポリシーに関するお問い合わせは、info@librasolutions.co.jpまでお願いいたします。
        </p>
        <div className={classes.revision}>
          2024年4月5日 制訂・施行
        </div>
      </div>
    </Box>
  </div>
);

export default withStyles(styles)(PrivacyPolicy);

