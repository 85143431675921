import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/styles';

import styles from './style';

interface Props {
  classes: any;
};

const ContactForm = ({ classes }: Props) => (
  <div className={classes.container}>
    <Box boxShadow={3} className={classes.box}>
        <form 
          name="contact" 
          method="POST" 
          data-netlify="true"
          data-netlify-honeypot="bot-field"
        >
            <input type="hidden" name="form-name" value="contact" />
            <input type="hidden" name="bot-field" />
            <div className={classes.contactForm}>
              <p>
                 各種お問い合わせはこちらのフォームよりお願いいたします。
              </p>
              <TextField
                id="name"
                className={classes.formTextField}
                name="name"
                label="お名前"
                type="text"
                fullWidth
                variant="outlined"
              />
              <TextField
                id="email"
                className={classes.formTextField}
                name="email"
                label="メールアドレス"
                type="email"
                fullWidth
                variant="outlined"
              />
              <TextField
                id="subject"
                className={classes.formTextField}
                name="subject"
                label="件名"
                type="text"
                fullWidth
                variant="outlined"
              />
              <TextField
                id="message"
                className={classes.formTextField}
                name="message"
                label="問い合わせ内容"
                fullWidth
                multiline
                rows={16}
                variant="outlined"
              />
              <Button
                type="submit"
                variant="contained"
              >
                送信
              </Button>
           </div>
         </form>
    </Box>
  </div>
);

export default withStyles(styles)(ContactForm);
