const styles = ({ app, typography }) => ({
  '@global': {
    "td": {
        "&:nth-child(1)": {
          borderWidth: 0,
          borderRightWidth: 1,
          borderColor: 'rgba(224, 224, 224, 1)',
          borderStyle: 'solid',
          textAlign: 'right',
        },
        "&:nth-child(2)": {
          borderWidth: 0,
          textAlign: 'left',
        }
    }
  },
  container: {
    display: 'flex',
    margin: `${typography.pxToRem(8)} auto 0`,
    maxWidth: app.maxWidth,
    width: '100%',
  },
  box: {
    maxWidth: app.maxWidth,
    width: '100%',
  },
  corporateTable: {
    borderCollapse: 'separate',
    borderSpacing: '0px 16px',
  },
});

export default styles;
