const styles = ({ app, typography }) => ({
  '@global': {
    h2: {
      fontSize: '28pt',
      marginTop: '0',
      marginBottom: '1em',
    },
    "td": {
        "&:nth-child(1)": {
          paddingLeft: "0",
          borderWidth: 0,
          borderRightWidth: 1,
          borderColor: 'rgba(224, 224, 224, 1)',
          borderStyle: 'solid',
          textAlign: 'right',
        },
        "&:nth-child(2)": {
          paddingRight: "0",
          borderWidth: 0,
          textAlign: 'left',
        }
    }
  },
  container: {
    display: 'flex',
    margin: `${typography.pxToRem(8)} auto 0`,
    maxWidth: app.maxWidth,
    width: '100%',
  },
  box: {
    maxWidth: app.maxWidth,
    width: '100%',
  },
  legal: {
    padding: '3em',
  },
  legalTable: {
    borderCollapse: 'separate',
    borderSpacing: '0px 16px',
  },

});

export default styles;
